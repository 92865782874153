<template>
  <a-row type="flex" justify="start" align="top">
    <a-checkbox v-model:checked="canLogin">Can login</a-checkbox>
  </a-row>

  <a-divider />

  <a-row justify="end">
    <a-space>
      <a-button @click="submit"> submit</a-button>
      <a-button @click="handelClose"> Cancle</a-button>
    </a-space>
  </a-row>
</template>

<script>
import { toRefs, reactive, inject, computed, createVNode } from "vue";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";

export default {
  props: ["canLogin", "userId"],
  emits: ["close"],
  setup(props, { emit }) {
    const { $msg, gql } = inject("$");
    const v = reactive({
      limit: 30,
      total: 0,
      currentPage: 1,
      page: 1,

      loading: false,

      options: [],
      checked: true,
      // selected: [],
    });

    const QL = gql`
      query ($page: Int, $limit: Int) {
        committers(page: $page, limit: $limit) {
          items {
            name
            email
            userId
          }
          total
        }
      }
    `;

    const selected = computed(() => {
      // return v.options.filter((e) => e.checked);

      let list = [];
      if (v.options.length >= 1)
        v.options.forEach((item) => {
          item.forEach((e) => {
            if (e.checked) list.push(e);
          });
        });

      return list;
    });

    const {
      result: committers,
      onResult,
      loading: listLoading,

      refetch,
    } = useQuery(
      QL,
      () => {
        return { page: v.page, limit: v.limit };
      },
      { fetchPolicy: "network-only" }
    );

    onResult((res) => {
      if (res.data) {
        let list = [];
        res.data.committers.items.forEach((item) => {
          let obj = { ...item };
          obj.indeterminate = Boolean(item.userId);

          obj.checked = selected.value.some((e) => e.email == item.email);
          list.push(obj);
        });

        // v.options = res.data.committers.items;
        // v.options = list;
        v.options[v.page - 1] = list;
        v.total = res.data.committers.total;
      }
    });

    function handleChange(item) {
      let target = v.options.find((e) => {
        e.name == item.name;
      });
      if (target) {
        if (target.userId) {
          target.indeterminate = false;
        }
      }

      v.selected = v.options.filter((e) => e.checked);
    }

    const MUTATE_QL = gql`
      mutation ($ids: [Int!]!, $canLogin: Boolean) {
        updateLogin(id: $ids, canLogin: $canLogin)
      }
    `;

    const {
      mutate: sendMessage,
      loading: sendMessageLoading,
      onDone: onSendMessageDone,
      onError: onSendMessageError,
    } = useMutation(MUTATE_QL);

    onSendMessageDone(() => {
      $msg.success("Update successfully");
      handelClose();
    });

    onSendMessageError((error) => {
      $msg.error(error.message);
    });

    function submit() {
      let emails = selected.value.map((e) => e.email);
      sendMessage({ ids: [props.userId], canLogin: props.canLogin });
    }

    function handelClose() {
      v.options.forEach((e) => (e.checked = false));
      emit("close");
    }

    function pageChange(page) {
      v.selected = v.options.filter((e) => e.checked);
      v.page = page;
      refetch();
    }

    function getStatus(item) {
      if (item.checked) {
        return false;
      } else if (item.userId) {
        return true;
      }
    }

    return {
      ...toRefs(v),

      //   list,
      listLoading,
      handleChange,
      // selected,
      sendMessage,
      handelClose,
      submit,
      pageChange,
      getStatus,
      selected,
    };
  },
};
</script>

<style>
</style>