<template>
  <a-row type="flex" justify="start" align="top">
    <a-checkbox v-model:checked="isAdmin">isAdmin</a-checkbox>
  </a-row>

  <a-divider />

  <a-row justify="end">
    <a-space>
      <a-button @click="submit"> submit</a-button>
      <a-button @click="handelClose"> Cancle</a-button>
    </a-space>
  </a-row>
</template>

<script>
import { toRefs, reactive, inject, computed, createVNode } from "vue";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { useStore } from "vuex";
export default {
  props: ["isAdmin", "userId"],
  emits: ["close"],
  setup(props, { emit }) {
    const { $msg, gql } = inject("$");
    const store = useStore();
    const v = reactive({
      limit: 30,
      total: 0,
      currentPage: 1,
      page: 1,

      loading: false,

      options: [],
      checked: true,
      // selected: [],
    });

    function handleChange(item) {
      let target = v.options.find((e) => {
        e.name == item.name;
      });
      if (target) {
        if (target.userId) {
          target.indeterminate = false;
        }
      }

      v.selected = v.options.filter((e) => e.checked);
    }

    const MUTATE_QL = gql`
      mutation ($ids: [Int!]!, $isAdmin: Boolean) {
        updateAdmin(id: $ids, isAdmin: $isAdmin)
      }
    `;

    const {
      mutate: sendMessage,
      loading: sendMessageLoading,
      onDone: onSendMessageDone,
      onError: onSendMessageError,
    } = useMutation(MUTATE_QL);

    onSendMessageDone(() => {
      $msg.success("Update successfully");
      // const id = store.state.userInfo.id;
      // console.log(id, props.userId);
      // if (id == props.userId) {
      //   let roles = [];
      //   props.isAdmin ? roles.push("ADMIN") : roles.push("USER");
      //   let userInfo = store.state.userInfo;
      //   userInfo.roles = roles;
      //   store.commit("SET_USERINFO", userInfo);
      //   store.commit("SET_ROLES", roles);
      // }
      handelClose();
    });

    onSendMessageError((error) => {
      $msg.error(error.message);
    });

    function submit() {
      sendMessage({ ids: [props.userId], isAdmin: props.isAdmin });
    }

    function handelClose() {
      v.options.forEach((e) => (e.checked = false));
      emit("close");
    }

    function pageChange(page) {
      v.selected = v.options.filter((e) => e.checked);
      v.page = page;
      refetch();
    }

    function getStatus(item) {
      if (item.checked) {
        return false;
      } else if (item.userId) {
        return true;
      }
    }

    return {
      ...toRefs(v),
      handelClose,
      submit,
    };
  },
};
</script>

<style>
</style>