<template>
  <div class="mb-10">
    <a-input-search placeholder="Please enter the search you want to search" @search="onSearch" />
  </div>


  <div v-for="item in options[page - 1]" :key="item.name">
    <a-checkbox v-model:checked="item.checked" :indeterminate="item.indeterminate" @click="handleChange(item)">
      {{ item.name }}

      <a-typography-text type="secondary">{{ item.email }}</a-typography-text>
    </a-checkbox>
  </div>

  <a-row type="flex" justify="center" align="top">
    <div style="margin-top: 20px">
      <a-pagination v-model:current="currentPage" :total="total" show-less-items @change="pageChange"
        :defaultPageSize="limit" size="small" />
    </div>
  </a-row>

  <a-divider />

  <a-row justify="end">
    <a-space>
      <a-button @click="submit">Submit</a-button>
      <a-button @click="handelClose">Cancel</a-button>
    </a-space>
  </a-row>
</template>

<script>
import { toRefs, reactive, inject, computed, createVNode } from "vue";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";

export default {
  props: ["userId"],
  emits: ["close"],
  setup(props, { emit }) {
    const { $msg, gql } = inject("$");
    const v = reactive({
      limit: 30,
      total: 0,
      currentPage: 1,
      page: 1,
      searchText: '',

      loading: false,

      options: [],
      checked: true,
      // selected: [],
    });

    const QL = gql`
      query ($page: Int, $limit: Int, $searchText: String ) {
        committers(page: $page, limit: $limit, searchText: $searchText) {
          items {
            name
            email
            userId
          }
          total
        }
      }
    `;

    const selected = computed(() => {
      // return v.options.filter((e) => e.checked);

      let list = [];
      if (v.options.length >= 1)
        v.options.forEach((item) => {
          item.forEach((e) => {
            if (e.checked) list.push(e);
          });
        });

      return list;
    });

    const {
      result: committers,
      onResult,
      loading: listLoading,
      onError,
      refetch,
    } = useQuery(
      QL,
      () => {
        return { page: v.page, limit: v.limit, searchText: v.searchText };
      },
      { fetchPolicy: "network-only" }
    );

    onResult((res) => {
      if (res.data) {
        let list = [];
        res.data.committers.items.forEach((item) => {
          let obj = { ...item };

          obj.indeterminate = Boolean(item.userId);

          if (item.userId == props.userId) {
            obj.checked = true;
            obj.indeterminate = false;
          } else {
            obj.checked = false;
          }
          // obj.checked = selected.value.some((e) => e.email == item.email); // 这里不太对

          list.push(obj);
        });

        // v.options = res.data.committers.items;
        // v.options = list;
        v.options[v.page - 1] = list;
        v.total = res.data.committers.total;
      }
    });

    onError((error) => {
      let { networkError, graphQLErrors } = error;
      if (networkError) {
        $msg.error("Nerwork Error");
      }
      if (graphQLErrors) {
        $msg.error(graphQLErrors[0].message);
      }
    });

    function handleChange(item) {
      item.checked = !item.checked;

      if (!item.checked && item.userId) {
        item.indeterminate = true;
      } else {
        item.indeterminate = false;
      }
    }

    const MUTATE_QL = gql`
      mutation ($emails: [String!]!, $userId: Int!) {
        connectCommittersToUser(emails: $emails, userId: $userId)
      }
    `;

    const {
      mutate: sendMessage,
      loading: sendMessageLoading,
      onDone: onSendMessageDone,
      onError: onSendMessageError,
    } = useMutation(MUTATE_QL);

    onSendMessageDone(() => {
      $msg.success("Added successfully");
      handelClose();
    });

    onSendMessageError((error) => {
      console.log(error);
      //   $msg.error("Request error");
    });

    function submit() {
      let emails = selected.value.map((e) => e.email);
      sendMessage({ emails, userId: props.userId });
    }

    function handelClose() {
      v.options.forEach((e) => (e.checked = false));
      emit("close");
    }

    function pageChange(page) {
      v.selected = v.options.filter((e) => e.checked);
      v.page = page;
      refetch();
    }

    function getStatus(item) {
      if (item.checked) {
        return false;
      } else if (item.userId) {
        return true;
      }
    }

    function onSearch(params) {
      // refetch();

      v.searchText = params
    }

    return {
      ...toRefs(v),

      //   list,
      listLoading,
      handleChange,
      // selected,
      sendMessage,
      handelClose,
      submit,
      pageChange,
      getStatus,
      selected,
      onSearch,
    };
  },
};
</script>

<style></style>