<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Users">
      <template #extra>
        <a-button key="1" type="primary" @click="shownewModal = true"
          >Add new user</a-button
        >
      </template>
    </a-page-header>

    <!-- New user -->
    <a-modal
      v-model:visible="shownewModal"
      title="New User"
      :maskClosable="false"
      :keyboard="false"
      centered
      @ok="shownewModal = false"
    >
      <a-checkbox-group v-model:value="newUsers">
        <a-row>
          <a-col
            :span="8"
            v-for="(item, index) in list.filter((e) => !e.canLogin)"
            :key="index"
          >
            <a-checkbox :value="item.id">{{ item.name }}</a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          :loading="sendMessageLoading"
          @click="sendMessage({ ids: newUsers, canLogin: true })"
          >Submit</a-button
        >
      </template>
    </a-modal>

    <a-table
      :dataSource="list"
      :columns="columns"
      :loading="listLoading"
      :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: currentPage,
        defaultPageSize: limit,
      }"
      @change="tableChange"
      rowKey="id"
      class="ant-table-striped"
      :rowClassName="
        (record, index) => (record.status == 'LOCKED' ? 'table-striped' : null)
      "
    >
      <template #name="{ text }"> {{ text }} </template>
      <template #username="{ text }"> {{ text }} </template>
      <template #email="{ text }">
        {{ text }}
      </template>
      <template #isAdmin="{ text }">
        {{ text ? "✔" : "" }}
      </template>
      <template #canLogin="{ text }">
        {{ text ? "✔" : "" }}
      </template>

      <template #tool="{ record }">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <SettingOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item
                @click="
                  visibleEdit = true;
                  tobeEditUser = record;
                "
                v-if="record.id != $store.state.userInfo.id"
              >
                Set admin
              </a-menu-item>
              <a-menu-item
                @click="
                  showSetConLoginModal = true;
                  tobeEditUser = record;
                "
                v-if="record.id != $store.state.userInfo.id"
              >
                Set can login
              </a-menu-item>
              <a-menu-item
                @click="
                  visible = true;
                  tobeEdit = record.id;
                "
              >
                Connect Committers
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>

    <a-modal
      v-model:visible="visibleEdit"
      title="Set Admin"
      @ok="visibleEdit = false"
      :footer="null"
      centered
      destroyOnClose
    >
      <EditUser
        @close="handelUpdateList"
        :isAdmin="tobeEditUser.isAdmin"
        :userId="tobeEditUser.id"
      />
    </a-modal>

    <a-modal
      v-model:visible="showSetConLoginModal"
      title="Set Can Login"
      @ok="showSetConLoginModal = false"
      :footer="null"
      centered
      destroyOnClose
    >
      <SetConLogin
        @close="handelUpdateList"
        :canLogin="tobeEditUser.canLogin"
        :userId="tobeEditUser.id"
      />
    </a-modal>

    <a-modal
      v-model:visible="visible"
      title="Connect Committers"
      @ok="visible = false"
      :footer="null"
      centered
      destroyOnClose
    >
      <ConnectCommitters @close="visible = false" :userId="tobeEdit" />
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },

  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Username",
    dataIndex: "username",
    slots: { customRender: "dueDate" },
  },
  {
    title: "Is Admin",
    dataIndex: "isAdmin",
    slots: { customRender: "isAdmin" },
  },
  {
    title: "Can Login",
    dataIndex: "canLogin",
    slots: { customRender: "canLogin" },
  },

  {
    title: "...",
    key: "tool",
    dataIndex: "id",
    slots: { customRender: "tool" },
  },
];
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { toRefs, reactive, inject, computed, createVNode } from "vue";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { Modal } from "ant-design-vue";
import ConnectCommitters from "./ConnectCommitters.vue";
import EditUser from "./EditUser.vue";
import SetConLogin from "./SetConLogin.vue";

import { SettingOutlined } from "@ant-design/icons-vue";

export default {
  components: { ConnectCommitters, SettingOutlined, EditUser, SetConLogin },
  setup(props) {
    const { $msg, gql } = inject("$");
    const v = reactive({
      limit: 10,
      total: 0,
      currentPage: 1,
      page: 1,
      shownewModal: false,
      newUsers: [],
      loading: false,

      visible: false,
      tobeEdit: "",

      visibleEdit: false,
      tobeEditUser: {},

      showSetConLoginModal: false,
    });

    const QL = gql`
      query ($page: Int, $limit: Int) {
        users(page: $page, limit: $limit, committer:false) {
          items {
            username
            name
            id
            canLogin
            isAdmin
          }
          total
        }
      }
    `;
    const {
      result: committers,
      onResult,
      loading: listLoading,
      onError,
      refetch,
    } = useQuery(
      QL,
      () => ({
        page: v.page,
        limit: v.limit,
      }),
      {
        fetchPolicy: "network-only",
      }
    );

    const list = useResult(committers, [], (data) => data.users.items);

    onResult((qres) => {
      v.total = qres.data.users.total;
    });

    onError((error) => {
      let { networkError, graphQLErrors } = error;
      if (networkError) {
        $msg.error("Nerwork Error");
      }
      if (graphQLErrors) {
        $msg.error(graphQLErrors[0].message);
      }
    });

    const plainOptions = computed(() => {
      let olist = list.value.filter((e) => !e.canLogin);
      return olist.map((e) => ({
        value: e.id,
        label: e.name,
      }));
    });

    const MUTATE_QL = gql`
      mutation ($ids: [Int!]!, $canLogin: Boolean) {
        updateLogin(id: $ids, canLogin: $canLogin)
      }
    `;

    const {
      mutate: sendMessage,
      loading: sendMessageLoading,
      onDone: onSendMessageDone,
      onError: onSendMessageError,
    } = useMutation(MUTATE_QL);

    onSendMessageDone(() => {
      $msg.success("Added successfully");
      v.newUsers = [];
      v.shownewModal = false;
      refetch();
    });

    onSendMessageError(() => {
      $msg.error("Request error");
    });

    const {
      mutate: sendDelMessage,
      loading: sendDelMessageLoading,
      onDone: onSendDelMessageDone,
      onError: onSendDelMessageError,
    } = useMutation(MUTATE_QL);

    const showDeleteConfirm = (user) => {
      Modal.confirm({
        title: () => `Are you sure delete this user (${user.name})?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => "Cannot recover after deletion",
        okText: () => "Yes",
        okType: "danger",
        cancelText: () => "No",
        onOk() {
          sendDelMessage({ ids: [user.id], canLogin: false });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };

    onSendDelMessageDone(() => {
      $msg.success("Remove successfully");
      refetch();
    });

    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
      refetch();
    }

    async function handelUpdateList() {
      refetch();
      v.visible = false;
      v.visibleEdit = false;
      v.showSetConLoginModal = false;
    }

    return {
      ...toRefs(v),
      columns,
      tableChange,
      plainOptions,
      list,
      listLoading,
      sendMessage,
      sendMessageLoading,
      showDeleteConfirm,
      sendDelMessage,
      handelUpdateList,
    };
  },
};
</script>

<style lang="scss" scoped></style>




